/**********************************************************/
/*                     INPUT TEXT                         */
/**********************************************************/

/* VARIABLES */

// Input Text
$input-border: 1px solid #aaa;
$input-radius: 2px;
$input-bg-color: $white;
$input-invalid-bg-color: #fff2ef;
$input-invalid-border-color: #d93900;
$input-font-color: $body-font-color;
$input-width: 100%;
$input-paddings: (
  small: 8px 10px,
  default: 10px,
  large: 13px,
);

$input-font-sizes: (
  small: 14px,
  default: 16px,
  large: 18px,
);

$input-border-color-focus: map-get($main-palette, secondary);
$input-outline-focus: 0;
$input-disabled-opacity: 0.5;
$input-disabled-bg-color: #F5F5F5;

// Select
$select-paddings: (
  small: 8px 25px 8px 10px,
  default: 12px 30px 12px 12px,
  large: 15px 35px 15px 15px,
);

$select-icon-top: (
    small: 10px,
    default: 12px,
    large: 16px,
);
$select-icon-right: (
    small: 5px,
    default: 5px,
    large: 5px,
);
$select-icon-stroke-width: (
    small: 1,
    default: 1,
    large: 1,
);

$select-icon-width-small: 15px;
$select-icon-height-small: 15px;

$select-background: white\0 !important;

$select-icon-font-size-small: 10px;
$select-icon-color: $light-dark;
$select-icon-width: 2em;
$select-icon-padding: 0.7em;
$select-icon-line-height: 1;
$select-icon-transform: scale(0.84, 0.42);

// Checkbox
$checkbox-font-color: $body-font-color;
$checkbox-bg-color: $white;
$checkbox-margin: 2px 5px 0 0;
$checkbox-height: 1.25em;
$checkbox-width: 1.25em;
$checkbox-font-size: 0.85em;
$checkbox-left: 1px;
$checkbox-width: 1.25em;
$checkbox-color: (
    primary: map-get($main-palette, primary),
    secondary: map-get($main-palette, secondary),
    minimal: $body-font-color,
    disabled: $light-dark,
);

// Searchbox
$searchbox-font-color: $body-font-color;
$searchbox-font-size: 19px;
$searchbox-line-height: 24px;
$searchbox-radius: 4px;
$searchbox-border: 1px solid #DBDBDB;
$searchbox-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.08);
$searchbox-padding: 0;
$searchbox-icon-container-padding: 7px 7px 2px 7px;
$searchbox-icon-height: 20px;
$searchbox-icon-width: 20px;
$searchbox-icon-stroke-width: 1;
$searchbox-input-font-size: 15px;
$searchbox-input-font-color: $body-font-color;
$searchbox-input-line-height: 18px;
$searchbox-input-letter-space: 0.2px;
$searchbox-input-font-weight: 600;
$searchbox-input-bg-color: transparent;
$searchbox-input-border: 0 hidden transparent;
$searchbox-input-padding: 8px 8px 8px 35px;
$searchbox-input-width: 250px;
$searchbox-input-focus-width: 500px;
$searchbox-input-focus-border-bottom: 2px solid map-get($main-palette, secondary);

/**********************************************************/


/*                 TEXT INPUT                 */
/**********************************************/
.ma-input {
    border: $input-border;
    -webkit-border-radius: $input-radius;
    -moz-border-radius: $input-radius;
    border-radius: $input-radius;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-color: $input-bg-color;
    color: $input-font-color;
    display: block;
    width: $input-width;

    &:focus {
        border-color: $input-border-color-focus;
        outline: $input-outline-focus;
    }

    &--small {
        padding: map-get($input-paddings, small);
        font-size: map-get($input-font-sizes, small);
    }

    &--default {
        padding: map-get($input-paddings, default);
        font-size: map-get($input-font-sizes, default);
    }

    &--large {
        padding: map-get($input-paddings, large);
        font-size: map-get($input-font-sizes, large);
    }

    &--invalid {
        background-color: $input-invalid-bg-color;
        border-color: $input-invalid-border-color;
    }
}

.ma-input[disabled] {
    opacity: $input-disabled-opacity;
    cursor: not-allowed;
    background-color: $input-disabled-bg-color;
}



/*                SELECT INPUT                */
/**********************************************/
.ma-select-container {
    position: relative;
    display: inline-block;
    vertical-align: bottom;


    /// SELECT CONTAINER SIZES

    &--small {
        .ma-select-arrow {
            position: absolute;
            top: map-get($select-icon-top, small);
            right: map-get($select-icon-right, small);
            line-height: 0;
            pointer-events: none;

            &__icon {
                stroke-width: map-get($select-icon-stroke-width, small);
                width: $select-icon-width-small;
                height: $select-icon-height-small;
            }
        }
    }

    &--default {
        .ma-select-arrow {
            position: absolute;
            top: map-get($select-icon-top, default);
            right: map-get($select-icon-right, default);
            line-height: 0;
            pointer-events: none;

            &__icon {
                stroke-width: map-get($select-icon-stroke-width, default);
            }
        }
    }

    &--large {
        .ma-select-arrow {
            position: absolute;
            top: map-get($select-icon-top, large);
            right: map-get($select-icon-right, large);
            line-height: 0;
            pointer-events: none;

            &__icon {
                stroke-width: map-get($select-icon-stroke-width, large);
            }
        }
    }

    &--block {
        width: $input-width;
    }

    .ma-select {
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        background: $select-background;
        line-height: normal;


        /// SELECT SIZES

        &--small {
            padding: map-get($select-paddings, small);
        }

        &--default {
            padding: map-get($select-paddings, default);
            font-size: map-get($input-font-sizes, default);
        }

        &--large {
            padding: map-get($select-paddings, large);
            font-size: map-get($input-font-sizes, large);
        }

        &--block {
            width: $input-width;
        }

        &--invalid {
            background-color: $input-invalid-bg-color;
            border-color: $input-invalid-border-color;
        }

    }

}



/*                 SEARCHBOX                  */
/**********************************************/
.ma-searchbox {
    font-size: $searchbox-font-size;
    line-height: $searchbox-line-height;
    letter-spacing: undefined;
    color: $searchbox-font-color;
    border-radius: $searchbox-radius;
    border: $searchbox-border;
    box-shadow: $searchbox-box-shadow;
    padding: $searchbox-padding;
    display: table;
    table-layout: fixed;
    position: relative;
    overflow: hidden;

    &__icon-container {
        float: left;
        padding: $searchbox-icon-container-padding;
        position: absolute;

        .feather-search {
            height: $searchbox-icon-height;
            width: $searchbox-icon-width;
            stroke-width: $searchbox-icon-stroke-width;
        }
    }

    &__input-container {
        overflow: hidden;
        position: relative;

        &__input {
            font-size: $searchbox-input-font-size;
            line-height: $searchbox-input-line-height;
            letter-spacing: $searchbox-input-letter-space;
            color: $searchbox-input-font-color;
            font-weight: $searchbox-input-font-weight;
            background-color: $searchbox-input-bg-color;
            border: $searchbox-input-border;
            padding: $searchbox-input-padding;
            text-overflow: ellipsis;
            outline: none;
            width: $searchbox-input-width;
            -webkit-transition: width 0.3s ease-in-out;
            -moz-transition: width 0.3s ease-in-out;
            -o-transition: width 0.3s ease-in-out;
            transition: width 0.3s ease-in-out;

            &:focus {
                width: $searchbox-input-focus-width;
                border-bottom: $searchbox-input-focus-border-bottom;
                outline: none;
            }
        }
    }
}



/*                 TEXTAREA                   */
/**********************************************/
.ma-textarea {
    resize: vertical;
}



/*                 CHECKBOX                   */
/**********************************************/
.ma-checkbox {
    input[type="checkbox"] {
        position: relative;
        -webkit-appearance: none;
        height: $checkbox-height;
        width: $checkbox-width;
        margin: $checkbox-margin;
        vertical-align: top;
        border: $input-border;
        -webkit-border-radius: $input-radius;
        -moz-border-radius: $input-radius;
        border-radius: $input-radius;
        background-color: $checkbox-bg-color;
        color: $checkbox-font-color;

        &:checked:before {
            content: "\2713";
            position: absolute;
            font-size: $checkbox-font-size;
            left: $checkbox-left;
            text-align: center;
            width: $checkbox-width;
        }
    }

    &--primary {
        input[type="checkbox"] {
            &:checked:before {
                color: map-get($checkbox-color, primary);
            }
        }
    }

    &--secondary {
        input[type="checkbox"] {
            &:checked:before {
                color: map-get($checkbox-color, secondary);
            }
        }
    }

    &--minimal {
        input[type="checkbox"] {
            &:checked:before {
                color: map-get($checkbox-color, minimal);
            }
        }
    }

    &--disabled {
        color: map-get($checkbox-color, disabled);
        cursor: not-allowed;

        input[type="checkbox"][disabled] {
            opacity: $input-disabled-opacity;
        }
    }
}
