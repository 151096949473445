/**********************************************************/
/*                        BUTTONS                         */
/**********************************************************/

/* VARIABLES */
$button-radius: 4px;
$button-border: 2px solid transparent;

$button-paddings: (
  small: 8px 16px,
  default: 12px 22px,
  large: 12px 24px,
);

$button-line-heights: (
  small: 15px,
  default: 22px,
  large: 22px,
);

$button-bg-color: (
    primary: map-get($main-palette, primary),
    primary-without-bg: transparent,
    secondary: map-get($main-palette, secondary),
    secondary-without-bg: transparent,
    minimal: $white,
    facebook: $global-facebook-color,
    google: $global-google-color,
);

$button-font-sizes: (
  small: 14px,
  default: 17px,
  large: 19px,
);

$button-font-weight: (
  small: $global-font-weight,
  default: $global-font-weight,
  large: $global-font-weight,
);

$button-font-colors: (
    primary: $white,
    primary-without-bg: map-get($main-palette, primary),
    secondary: $white,
    secondary-without-bg: map-get($main-palette, secondary),
    minimal: $body-font-color,
    facebook: $white,
    google: $body-font-color,
);

$button-border-colors: (
    primary: transparent,
    primary-without-bg: map-get($main-palette, primary),
    secondary: transparent,
    secondary-without-bg: map-get($main-palette, secondary),
    minimal: #C4C4C4,
    facebook: transparent,
    google: $body-font-color,
);

$button-opacity-disabled: 0.25;
$button-block-width: 100%;

$button-helpful-font-color: $light-dark;
$button-helpful-bg-color: $white;
$button-helpful-bg-color-hover: #f5f5f5;
$button-helpful-colors: (
    primary: map-get($main-palette, primary),
    secondary: map-get($main-palette, secondary),
    minimal: $button-helpful-font-color,
);
$button-helpful-paddings: (
    small: 4px 12px,
);
$button-helpful-font-sizes: (
    icon: 15px,
    text: 15px,
    count: 14px,
);
$button-helpful-text-padding: 10px;
$button-helpful-count-padding: 10px;
$button-helpful-count-margin: 10px;
$button-helpful-count-border-color: #dce0e0;
$button-helpful-count-font-weight: $global-font-weight;


/**********************************************************/

.ma-btn {

    cursor: pointer;
    position: relative;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-border-radius: $button-radius;
    -moz-border-radius: $button-radius;
    border-radius: $button-radius;
    border: $button-border;
    width: auto;
    -webkit-font-smoothing: antialiased;


    ///BUTTON_SIZE

    &--small {
        padding: map-get($button-paddings, small);
        font-size: map-get($button-font-sizes, small);
        font-weight: map-get($button-font-weight, small);
        line-height: map-get($button-line-heights, small);
    }

    &--default {
        padding: map-get($button-paddings, default);
        font-size: map-get($button-font-sizes, default);
        font-weight: map-get($button-font-weight, default);
        line-height: map-get($button-line-heights, default);
    }

    &--large {
        padding: map-get($button-paddings, large);
        font-size: map-get($button-font-sizes, large);
        font-weight: map-get($button-font-weight, large);
        line-height: map-get($button-line-heights, large);
    }

    &--block {
        display: block;
        white-space: normal;
        width: $button-block-width;
    }


    ///BUTTON_COLOR

    &--primary {
        background: 0 0;
        color: map-get($button-font-colors, primary);
        background-color: map-get($button-bg-color, primary);

        &:hover {
            background: 0 0;
            color: map-get($button-font-colors, primary);
            background-color: map-get($button-bg-color, primary);
        }

        &:focus {
            background: 0 0;
            color: map-get($button-font-colors, primary);
            background-color: map-get($button-bg-color, primary);
        }
    }

    &--primary-without-bg {
        background: 0 0;
        color: map-get($button-font-colors, primary-without-bg);
        background-color: map-get($button-bg-color, primary-without-bg);
        border-color: map-get($button-border-colors, primary-without-bg);

        &:hover {
            background: 0 0;
            color: map-get($button-font-colors, primary-without-bg);
            background-color: map-get($button-bg-color, primary-without-bg);
            border-color: map-get($button-border-colors, primary-without-bg);
        }

        &:focus {
            background: 0 0;
            color: map-get($button-font-colors, primary-without-bg);
            background-color: map-get($button-bg-color, primary-without-bg);
            border-color: map-get($button-border-colors, primary-without-bg);
        }
    }

    &--secondary {
        background: 0 0;
        color: map-get($button-font-colors, secondary);
        background-color: map-get($button-bg-color, secondary);

        &:hover {
            background: 0 0;
            color: map-get($button-font-colors, secondary);
            background-color: map-get($button-bg-color, secondary);
        }

        &:focus {
            background: 0 0;
            color: map-get($button-font-colors, secondary);
            background-color: map-get($button-bg-color, secondary);
        }
    }

    &--secondary-without-bg {
        color: map-get($button-font-colors, secondary-without-bg);
        background-color: map-get($button-bg-color, secondary-without-bg);
        border-color: map-get($button-border-colors, secondary-without-bg);

        &:hover {
            color: map-get($button-font-colors, secondary-without-bg);
            background-color: map-get($button-bg-color, secondary-without-bg);
            border-color: map-get($button-border-colors, secondary-without-bg);
        }

        &:focus {
            color: map-get($button-font-colors, secondary-without-bg);
            background-color: map-get($button-bg-color, secondary-without-bg);
            border-color: map-get($button-border-colors, secondary-without-bg);
        }
    }

    &--minimal {
        background-color: map-get($button-bg-color, minimal);
        color: map-get($button-font-colors, minimal);
        border: 1px solid map-get($button-border-colors, minimal);

        &:hover {
            background-color: map-get($button-bg-color, minimal);
            color: map-get($button-font-colors, minimal);
            border: 1px solid map-get($button-border-colors, minimal);
        }

        &:focus {
            background-color: map-get($button-bg-color, minimal);
            color: map-get($button-font-colors, minimal);
            border: 1px solid map-get($button-border-colors, minimal);
        }
    }


    /*              SPECIAL BUTTONS               */
    /**********************************************/


    //Helpful Button
    &--helpful {
        border: 1px solid map-get($button-border-colors, minimal);
        overflow: hidden;
        background: $button-helpful-bg-color;

        ///HELPFUL_BUTTON_SIZE

        &--small {
            padding: map-get($button-helpful-paddings, small);
        }

        ///HELPFUL_BUTTON_COLOR

        &--primary {
            color: map-get($button-helpful-colors, primary);

            &:hover {
                border-color: map-get($button-helpful-colors, primary);
            }
        }

        &--secondary {
            color: map-get($button-helpful-colors, secondary);

            &:hover {
                border-color: map-get($button-helpful-colors, secondary);
            }
        }

        &--minimal {
            color: map-get($button-helpful-colors, minimal);

            &:hover {
                background-color: $button-helpful-bg-color-hover;
            }
        }


        ///HELFUL_BUTTON_ELEMENTS

        i {
            font-size: map-get($button-helpful-font-sizes, icon);
        }

        &__text {
            font-size: map-get($button-helpful-font-sizes, text);
            padding-left: $button-helpful-text-padding;
            display: inline;
            font-weight: normal;
        }

        &__count {
            font-size: map-get($button-helpful-font-sizes, count);
            border-left: 1px solid $button-helpful-count-border-color;
            margin-left: $button-helpful-count-margin;
            padding-left: $button-helpful-count-padding;
            display: inline-block;
            overflow: hidden;
            font-weight: $button-helpful-count-font-weight;
            vertical-align: bottom;
        }
    }


    //Facebook Button
    &--facebook {
        color: map-get($button-font-colors, facebook);
        background-color: map-get($button-bg-color, facebook);
    }

    &--facebook & {
        &__content {
            margin: 0px auto;
            display: table;

            &__icon-container {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }


    //Google Button
    &--google {
        color: map-get($button-font-colors, google);
        background-color: map-get($button-bg-color, google);
        border-color: map-get($button-border-colors, google);
    }

    &--google & {
        &__content {
            margin: 0px auto;
            display: table;

            &__icon-container {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

}

.ma-btn[disabled] {
    opacity: $button-opacity-disabled;
    cursor: not-allowed;
}
