/**********************************************************/
/*                         LINK                           */
/**********************************************************/

/* VARIABLES */
$link-simple-font-sizes: (
  small: map-get($global-font-sizes, small),
  default: map-get($global-font-sizes, default),
  large: map-get($global-font-sizes, large),
);

$link-font-colors: (
secondary: map-get($main-palette, secondary),
minimal: $body-font-color,
);

$link-border: 1px solid transparent;
$link-font-weight: 400;

/**********************************************************/

.ma-link {
    text-decoration: none;
    font-weight: normal;
    border: $link-border;
    transition: background 300ms ease-out;
    cursor: pointer;


    /*              SIMPLE LINK              */
    /*****************************************/

    &--simple {
        letter-spacing: 0;
        font-style: normal;
        font-weight: $link-font-weight;

        ///SIMPLE LINK SIZE

        &--small {
            font-size: map-get($link-simple-font-sizes, small);
        }

        &--default {
            font-size: map-get($link-simple-font-sizes, default);
        }

        &--large {
            font-size: map-get($link-simple-font-sizes, large);
        }

        ///SIMPLE LINK COLOR

        &--minimal {
            color: map-get($link-font-colors, minimal);

            &:hover:enabled, &:focus {
                color: map-get($link-font-colors, minimal);
                text-decoration: underline;
            }
        }

        &--secondary {
            color: map-get($link-font-colors, secondary);

            &:hover:enabled, &:focus {
                color: map-get($link-font-colors, secondary);
                text-decoration: underline;
            }
        }

    }

}
