/**********************************************************/
/*                         STARS                          */
/**********************************************************/

/* VARIABLES */
$star-font-color: map-get($main-palette, secondary);
$star-font-sizes: (
  tiny: 14px,
  small: 18px,
  default: 24px,
  large: 30px,
);

$star-label-container-margin-left: 3px;


/**********************************************************/


.ma-stars {
    display:inline-block;

    &__icon {
        color: $star-font-color;

        &--tiny {
            font-size: map-get($star-font-sizes, tiny)!important;
        }

        &--small {
            font-size: map-get($star-font-sizes, small)!important;
        }

        &--default {
            font-size: map-get($star-font-sizes, default)!important;
        }

        &--large {
            font-size: map-get($star-font-sizes, large)!important;
        }
    }

}

.ma-stars-withLabel {
    &__label-container {
        display: inline-block;
        vertical-align: top;
        margin-left: $star-label-container-margin-left;
    }
}
