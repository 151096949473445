/**********************************************************/
/*                        HEADER                          */
/**********************************************************/

/* VARIABLES */
$header-height: 65px;
$header-bg-color: $white;
$header-border-bottom: 1px solid #DBDBDB;
$header-width: 100%;
$header-z-index: 200;

$header-lg-logo-content-height: 64px;
$header-lg-logo-content-padding: 0 24px;

$header-lg-search-width: 100%;
$header-lg-search-form-margin: 0;
$header-lg-search-form-z-index: 11;
$header-lg-search-bar-border-left: 1px solid #DBDBDB;
$header-lg-search-bar-icon-top: 22px;
$header-lg-search-bar-icon-left: 16px;
$header-lg-search-bar-icon-color: $body-font-color;
$header-lg-search-bar-icon-z-index: 1;
$header-lg-search-bar-icon-stroke-width: 1;
$header-lg-search-bar-icon-width: 20px;
$header-lg-search-bar-icon-height: 20px;

$header-lg-search-bar-input-height: 64px;
$header-lg-search-bar-input-width: 100%;
$header-lg-search-bar-input-padding: 0 0 0 48px;
$header-lg-search-bar-input-border: 0 hidden transparent;
$header-lg-search-bar-input-outline: 0;
$header-lg-search-bar-input-font-size: 16px;

$header-lg-nav-list-height: 64px;
$header-lg-nav-list-padding: 0;
$header-lg-nav-list-margin: 0;

$header-lg-nav-list-btn-border: 0 hidden transparent;
$header-lg-nav-list-btn-font-weight: 600;
$header-lg-nav-list-btn-height: 64px;
$header-lg-nav-list-btn-line-height:64px;
$header-lg-nav-list-btn-margin: 0;
$header-lg-nav-list-btn-padding: 0 16px;

$header-lg-nav-list-btn-hover-border-bottom: 2px solid #767676;

/**********************************************************/

.ma-header {
    height: $header-height;
    background: $header-bg-color;
    border-bottom: $header-border-bottom;
    display: block;
    width: $header-width;
    z-index: $header-z-index;


    ///HEADER_ELEMENTS

    //Logo container
    &__logo-container {
        position: relative;

        .ma-header--large & {
            display: table-cell;
            vertical-align: middle;

            .content {
                display: table-cell;
                height: $header-lg-logo-content-height;
                position: relative;
                text-align: center;
                text-decoration: none;
                padding: $header-lg-logo-content-padding;
                vertical-align: middle;
                white-space: nowrap;
            }
        }
    }

    //Search Bar container
    &__search-container {

        .ma-header--large & {
            display: table-cell;
            width: $header-lg-search-width;
            vertical-align: middle;

            .form-content {
                margin: $header-lg-search-form-margin;
                position: relative;
                z-index: $header-lg-search-form-z-index;
            }

            .ma-search-bar {
                position: relative;
                border-left: $header-lg-search-bar-border-left;

                &__icon {
                    position: absolute;
                    top: $header-lg-search-bar-icon-top;
                    color: $header-lg-search-bar-icon-color;
                    left: $header-lg-search-bar-icon-left;
                    z-index: $header-lg-search-bar-icon-z-index;
                    stroke-width: $header-lg-search-bar-icon-stroke-width;
                    width: $header-lg-search-bar-icon-width;
                    height: $header-lg-search-bar-icon-height;
                }

                &__input {
                    height: $header-lg-search-bar-input-height;
                    width: $header-lg-search-bar-input-width;
                    padding: $header-lg-search-bar-input-padding;
                    border: $header-lg-search-bar-input-border;
                    outline: $header-lg-search-bar-input-outline;
                    font-size: $header-lg-search-bar-input-font-size;
                }

            }
        }

    }

    //Nav Bar container
    &__nav-container {

        .ma-header--large & {
            display: table-cell;
            vertical-align: middle;

            .ma-nav-list {
                display: table;
                list-style: none;
                padding: $header-lg-nav-list-padding;
                margin: $header-lg-nav-list-margin;
                height: $header-lg-nav-list-height;

                &__item {
                    display: table-cell;

                    &__btn {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        background: transparent;
                        border: $header-lg-nav-list-btn-border;
                        color: inherit;
                        font-weight: $header-lg-nav-list-btn-font-weight;
                        display: inline-block;
                        height: $header-lg-nav-list-btn-height;
                        line-height: $header-lg-nav-list-btn-line-height;
                        text-decoration: none;
                        margin: $header-lg-nav-list-btn-margin;
                        position: relative;
                        padding: $header-lg-nav-list-btn-padding;
                        white-space: nowrap;

                        &:hover {
                            border-bottom: $header-lg-nav-list-btn-hover-border-bottom;
                        }

                        .content {
                            display: inline-block;
                            padding: 8px 0;
                            vertical-align: middle;
                            line-height: 1;
                        }
                    }
                }
            }
        }

    }


    ///HEADER_MODIFIERS

    &--large {
        display: table;
        width: 100%;
    }
}
